html {
  height: 100%;
  overscroll-behavior-y: none;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  overscroll-behavior-y: none;
  padding: 0px;
  margin: 0px;
}

#__next {
  height: 100%;
  min-height: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.visibleOnHover {
  visibility: hidden;
}

.visibleOnHoverResponsive {
  visibility: hidden;
}

@keyframes aniAppearFromBehind {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes aniAppearFromBehindWithOpacity {
  0% {
    opacity: 0.4;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes aniSelected {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

a.a--button {
  text-decoration: none;
}

.eff-appear-enter {
  opacity: 0;
  transform: scale(0.9);
}

.eff-appear-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
  transition-delay: 100ms;
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/inter12/300.woff2') format('woff2'),
      url('/fonts/inter12/300.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/inter12/regular.woff2') format('woff2'),
      url('/fonts/inter12/regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/inter12/500.woff2') format('woff2'),
      url('/fonts/inter12/500.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/inter12/600.woff2') format('woff2'),
      url('/fonts/inter12/600.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/inter12/700.woff2') format('woff2'),
      url('/fonts/inter12/700.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/inter12/800.woff2') format('woff2'),
      url('/fonts/inter12/800.ttf') format('truetype');
}